<template>
<loader v-bind="{ loading }">
  <columns>
    <column>
      <columns>
        <column>
          <div class="box is-fullheight is-round p-5">
            <icon icon="helmet-safety" class="fa-2x has-text-grey"/>
            <p>
              Total # of Work Orders
            </p>
            <div class="is-flex is-align-items-center is-justify-content-space-between">
              <p class="is-size-3">{{ overview.total_work_orders | abbreviateCount }}</p>
              <difference-tag :last="overview.work_orders_last_month" :current="overview.work_orders_this_month" />
            </div>
          </div>
        </column>
        <column>
          <div class="box is-fullheight is-round p-5">
            <icon icon="list-check" class="fa-2x has-text-grey"/>
            <p>
              Total # Checklists
            </p>
            <div class="is-flex is-align-items-center is-justify-content-space-between">
              <p class="is-size-3">{{ overview.total_checklists | abbreviateCount }}</p>
              <difference-tag :last="overview.checklists_last_month" :current="overview.checklists_this_month" />
            </div>
          </div>
        </column>
        <column>
          <div class="box is-fullheight is-round p-5">
            <icon icon="street-view" class="fa-2x has-text-grey"/>
            <p>
              Total # of Staff
            </p>
            <div class="is-flex is-align-items-center is-justify-content-space-between">
              <p class="is-size-3">{{ overview.total_staff | abbreviateCount }}</p>
            </div>
          </div>
        </column>
        <column>
          <div class="box is-fullheight is-round p-5">
            <icon icon="people-arrows" class="fa-2x has-text-grey"/>
            <p>
              Total # of Teams
            </p>
            <div class="is-flex is-align-items-center is-justify-content-space-between">
              <p class="is-size-3">{{ overview.total_teams | abbreviateCount }}</p>
            </div>
          </div>
        </column>
      </columns>

      <columns>
        <column>
          <h4 class="title is-4">Recent Activity</h4>
          <div class="index-rows">
            <div class="box is-marginless" v-for="workOrder in overview.recent_work_orders" :key="workOrder.uuid">
              <columns>
                <column>
                  <router-link class="is-block" :to="{
                    name: 'work-order-manager',
                    params: {
                      workOrder: workOrder.uuid
                    }
                  }">
                    #{{ workOrder.number }}
                    {{ workOrder.reference }}
                    <span v-if="jobIsOverdue(workOrder)" class="has-text-danger" v-tooltip="`This work order is passed due.`">
                      <icon icon="stopwatch" />
                    </span>
                  </router-link>
                  <small class="has-text-grey">For {{ workOrder.customer.name }} at {{ workOrder.location.name }}, Updated {{
                  workOrder.updated_at | asCalendar }}</small>
                </column>
                <column class="is-narrow is-flex is-align-items-center is-justify-content-end">
                  <span v-tooltip="'The team assigned to this Work Order'">
                    <icon icon="people-arrows" class="has-text-grey mr-1" />
                    <span>{{ workOrder.team.name }}</span>
                  </span>
                </column>
                <column class="is-1 is-flex is-align-items-center is-justify-content-end">
                  <circular-progress-bar tooltip="Current progress of the Work Order" :total="workOrder.inspections.length"
                    :total-complete="workOrder.inspections.map(inspection => inspection.closed_off_at).length"
                    backgroundColor="#212127" />
                </column>
                <column class="is-1 is-flex is-align-items-center is-justify-content-center">
                  <priority-indicator :priority="workOrder.priority" />
                </column>
                <column class="is-narrow is-flex is-align-items-center is-justify-content-end">
                  <outcome-tag :status="workOrder.status" />
                </column>
              </columns>
            </div>
          </div>
        </column>
      </columns>
    </column>
    <column class="is-3"></column>
  </columns>
</loader>  
</template>
<script>
import { mapGetters } from 'vuex'
import moment from 'moment'
export default {
  
  data: () => ({
    loading: true
  }),

  async beforeCreate() {
    await this.$store.dispatch('branch/loadOverview', this.$route.params.branch)
    this.loading = false
  },

  methods: {
    jobIsOverdue(workOrder) {
      if (!workOrder.ending_at) return false
      return moment(workOrder.ending_at).isBefore(moment()) && !workOrder.closed_off_at
    },
  },

  computed: {
    ...mapGetters('branch', [
      'overview'
    ])
  }

}
</script>